import { useContext, useState, useEffect, useRef } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { UserStates } from 'state/userSessionState/userSessionStateSlice';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import CallControls from 'modules/ipcts-call-session/components/controls/call-controls.component';
import { Editor, EditorProvider } from 'shared/components/ui/editor';
import * as Styled from 'components/AgentSession/AgentSession.styled';
import Chronometer from 'components/Chronometer';
import { HandoffScreen } from 'components/Handoffs/HandoffScreen';
import logger from 'services/logger';
import {
  BillingSessionStates,
  useSendDataScienceEventMessage,
} from 'shared/hooks/data-science-api/use-send-data-science-event-message.hook';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';
import { NON_CRIT_HANDOFFS } from 'shared/hooks/axon/ipcts-gw/use-gateway.hook';

const AgentSession = () => {
  const [isHandoff, setIsHandoff] = useState(false);
  const [handoffType, setHandoffType] = useState<string | null>(null);
  const captions = useSelector((state: RootState) => state.captions.value);
  const user = useSelector((state: RootState) => state.user.value);
  const { updateUserState } = useUserState();
  const location = useLocation();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const { sendSessionUpdateEventMessage } = useSendDataScienceEventMessage();
  const navigate = useNavigate();

  const {
    isCallActive,
    setIsCallActive,
    dispositionSelected,
    setDispositionSelected,
    setSilentDispositionSelected,
    handleQueueCancel,
    callId,
    agentId,
  } = useContext(LandingPageContext);

  const {
    isActiveSession,
    isTokenReady,
    cardCaptionCallRef,
    reset,
    addCustomCaption,
    captionAreaFontSize,
    captionAreaLineHeight,
    captionAreaFontFamily,
    sendCallDisposition,
    sendSilentDisposition,
    sendTerminateCall,
    sendHandoffRequestGW,
    closeConnections,
    sendNotAcceptingCalls,
  } = useContext(IPCTSSessionContext);

  const dispositionCallEnd = () => {
    reset();
    setIsCallActive(false);
  };

  useEffect(() => {
    updateUserState(UserStates.IN_CALL, callId);
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [captions]);

  useEffect(() => {
    const handleNonCritHandoff = async () => {
      const callLength = localStorage.getItem('callLength') || 0;
      const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo')!);
      const DesktopVersion = localStorage.getItem('DesktopVersion') || '';

      await logger.info({
        callId,
        handoffType: handoffType || '',
        callLength: +callLength,
      });

      await sendSessionUpdateEventMessage(
        tokenInfo.accessToken,
        user.sessionLoginId,
        agentId,
        BillingSessionStates.LeaveQueue,
        user.agentEmail,
        DesktopVersion
      );
    };

    if (handoffType && NON_CRIT_HANDOFFS.includes(handoffType)) {
      const WINDOW_TO_DISPLAY_HANDOFF_SCREEN = 2000;
      handleNonCritHandoff();
      sendHandoffRequestGW(handoffType);
      sendNotAcceptingCalls('handoff', handoffType);
      handleQueueCancel();
      setTimeout(() => {
        localStorage.removeItem('callLength');
        closeConnections(false);
        navigate('/agent-lobby');
      }, WINDOW_TO_DISPLAY_HANDOFF_SCREEN);
    }
  }, [handoffType]);

  const handoffCallEnd = () => {
    dispositionCallEnd();
    setIsHandoff(true);
  };

  if (handoffType && NON_CRIT_HANDOFFS.includes(handoffType)) {
    return <HandoffScreen />;
  }

  if (!isCallActive) {
    return <Navigate to="/agent-queue" state={{ from: location }} />;
  }

  return (
    <Styled.DataContainer>
      <Styled.LeftContainer>
        <Styled.CardSessionContainer ref={scrollContainerRef}>
          <Styled.CardCaptionCall
            ref={cardCaptionCallRef}
            className="vertical-default-scroll"
            isDisabled={isActiveSession && !isTokenReady}
            fontSize={captionAreaFontSize}
            lineHeight={captionAreaLineHeight}
            fontFamily={captionAreaFontFamily}
          >
            <div className="editor-provider-wrapper">
              <EditorProvider>
                <Editor />
              </EditorProvider>
            </div>
            <div id="test-div" style={{ display: 'none', color: 'white' }}>
              {Object.keys(captions).length}
            </div>
          </Styled.CardCaptionCall>
        </Styled.CardSessionContainer>
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <Styled.ChronoContainer>
          <Chronometer
            id={'callLengthTimer'}
            timeWrapperProps={{ fontWeight: '600', width: '100%' }}
            inQueue
          />
        </Styled.ChronoContainer>
        <CallControls
          setHandoffType={setHandoffType}
          dispositionCallEnd={dispositionCallEnd}
          handoffCallEnd={handoffCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
          sendCallDisposition={sendCallDisposition}
          sendSilentDisposition={sendSilentDisposition}
          sendTerminateCall={sendTerminateCall}
        />
      </Styled.RightContainer>
    </Styled.DataContainer>
  );
};

export default AgentSession;
