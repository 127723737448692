import LogoutSVG from 'assets/icons/logout-button-icon.svg';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import * as React from 'react';
import * as Styled from './AppHeader.styled';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const KEY_FROM_ELECTRON_API = 'logoutAttempt';
const NOT_ALLOWED_TO_LOGOUT_FRON_ROUTES = ['/agent-queue', '/agent-session'];

const DesktopHeader = () => {
  const { pathname } = useLocation();
  const { logout, sendLogOutMessage } = React.useContext(AuthenticationContext);
  const user = useSelector((state: RootState) => state.user.value);
  const { updateUserState } = useUserState();

  const handleLogout = async () => {
    if (window?.electronAPI && KEY_FROM_ELECTRON_API in window?.electronAPI) {
      await updateUserState(UserStates.OFFLINE);
      await sendLogOutMessage();
      window?.electronAPI?.logoutAttempt(false);
    } else {
      await logout();
    }
  };

  const shouldLogoutButtonBeRendered =
    !NOT_ALLOWED_TO_LOGOUT_FRON_ROUTES.includes(pathname);

  return (
    <Styled.DesktopHeaderContainer>
      <Styled.RightContainer>
        {shouldLogoutButtonBeRendered ? (
          <Styled.LogoutButton id="logoutButton" onClick={handleLogout}>
            <Styled.LogoutSVG src={LogoutSVG} />
            Log out
          </Styled.LogoutButton>
        ) : null}
        <Styled.UserInfoContainer>
          <Styled.Username id="userText">{user?.agentEmail}</Styled.Username>
        </Styled.UserInfoContainer>
      </Styled.RightContainer>
    </Styled.DesktopHeaderContainer>
  );
};

export default DesktopHeader;
