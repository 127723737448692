import * as Styled from './Dispositions.styled';
import { useState } from 'react';
import DefaultDisposition from './DefaultDisposition';
import BusyDisposition from './BusyDisposition';
import NoAnswerDisposition from './NoAnswerDisposition';
import SilentDisposition from './SilentDisposition';
import SpecialToneDisposition from './SpecialToneDisposition';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';

const Dispositions = ({
  sendCallDisposition,
  sendSilentDisposition,
  sendTerminateCall,
}: {
  sendCallDisposition: (disposition: DispositionNames) => void;
  sendSilentDisposition: (
    receviedNonSilentCaption: boolean
  ) => DispositionNames;
  sendTerminateCall: (reason: ReasonCodes) => void;
}) => {
  const [selectedDisposition, setSelectedDisposition] =
    useState<DispositionNames>(DispositionNames.Normal);

  const cancelDisposition = () => {
    sendCallDisposition(DispositionNames.Normal);
  };

  const getScreen = () => {
    const screen = {
      [DispositionNames.Busy]: (
        <BusyDisposition
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
        />
      ),
      [DispositionNames.Illegit]: <></>,
      [DispositionNames.NoAnswer]: (
        <NoAnswerDisposition
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
        />
      ),
      [DispositionNames.Normal]: (
        <DefaultDisposition
          setSelectedDisposition={setSelectedDisposition}
          sendSilentDisposition={sendSilentDisposition}
          sendCallDisposition={sendCallDisposition}
        />
      ),
      [DispositionNames.OthLang]: <></>,
      [DispositionNames.SilentAnsMachine]: <></>,
      [DispositionNames.SilentMidCall]: (
        <SilentDisposition
          silentDisposition={DispositionNames.SilentMidCall}
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
          sendTerminateCall={sendTerminateCall}
        />
      ),
      [DispositionNames.SilentNoTimer]: (
        <SilentDisposition
          silentDisposition={DispositionNames.SilentNoTimer}
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
          sendTerminateCall={sendTerminateCall}
        />
      ),
      [DispositionNames.SilentTimerRunning]: (
        <SilentDisposition
          silentDisposition={DispositionNames.SilentTimerRunning}
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
          sendTerminateCall={sendTerminateCall}
        />
      ),
      [DispositionNames.SpecialTone]: (
        <SpecialToneDisposition
          setSelectedDisposition={setSelectedDisposition}
          cancelDisposition={cancelDisposition}
        />
      ),
    }[selectedDisposition];

    return screen;
  };

  return (
    <Styled.DispositionsContainer>{getScreen()}</Styled.DispositionsContainer>
  );
};
export default Dispositions;
